import * as React from 'react'
import { Typography, TypographyProps, styled } from '@mui/material'
import { colors } from '../constants/colors'
import { Verified } from '@mui/icons-material'

export const T3 = styled(Typography)`
  ${({ theme }) => `
    font-size: 13px;
    ${theme.breakpoints.up('md')} {
      font-size: 15px;
    }
  `}
`

export const Eyebrow = styled(Typography)`
  font-size: 12px;
  text-transform: uppercase;
`

export const ListTitle = (props: TypographyProps) => {
  return <StyledListTitle variant="caption" {...props} />
}
export const StyledListTitle = styled(Typography)`
  color: ${colors.BLACK_50};
`
export const ProfileName = ({ name, is_verified }: { name?: string; is_verified?: boolean }) => {
  return (
    <Typography variant="h5" component="p" color="textSecondary">
      <span style={{ verticalAlign: 'middle', lineHeight: 0 }}>{name}</span>
      {is_verified && (
        <span style={{ verticalAlign: 'middle', lineHeight: 0 }}>
          <Verified sx={{ ml: 1, color: 'currentcolor' }} />
        </span>
      )}
    </Typography>
  )
}
